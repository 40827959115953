import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import s from './Footer.scss';
import Grid from '../Grid';
import List from '../List';
import Page from '../Page';
import SvgIcon from '../SvgIcon';
import { footerMessages as messages } from '../../defineMessages';
import GoGiftLogo from '../GoGiftLogo';
import Label from '../Label';
import { convertHtmlStringToJSX } from '../../htmlStringParser';

const Footer = ({ intl, presentations, logoUrl, showWhiteLabelLogoUrl }) => {
  const {
    countryFooterPaymentIcons = 'dankort,klarna,maestro,mastercard,mobilepay,paypal,sofort,swish,trustly,vipps,visa',
  } = presentations;

  const paymentIcons = countryFooterPaymentIcons
    .split(',')
    .map(pi => pi.trim().toLowerCase());

  const footerColumns = Object.keys(presentations)
    .filter(p => p.substr(0, 16) === 'countryFooterCol' && p.length === 17)
    .sort();

  let grids = 24;
  if (footerColumns && footerColumns.length) {
    grids = Math.ceil(24 / footerColumns.length);
  }

  return (
    <footer id="footer" className={s.footer}>
      <Page>
        <Grid container spacing={16} wrap="nowrap">
          <Grid item sm={16} md={20} className="hidden-xs">
            <Grid container spacing={16} wrap="nowrap">
              {footerColumns.map(column => (
                <Grid key={column} item md={grids}>
                  {convertHtmlStringToJSX(presentations[column])}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={24} sm={8} md={4}>
            <List container component="div" wrap="nowrap" spacing={8}>
              <List item component="div">
                <Label tag="p" className={s.tagline}>
                  {intl.formatMessage({ ...messages.identity })}
                </Label>
              </List>
              <List item component="div">
                <div className={s.logo}>
                  {showWhiteLabelLogoUrl ? (
                    <img src={logoUrl} role="presentation" alt="logo" />
                  ) : (
                    <GoGiftLogo />
                  )}
                </div>
              </List>
            </List>
          </Grid>
        </Grid>
        <div className={cx(s['card-options'])}>
          {paymentIcons.map(n => (
            <SvgIcon name={n} key={n} />
          ))}
        </div>
      </Page>
    </footer>
  );
};

Footer.propTypes = {
  intl: PropTypes.shape().isRequired,
  presentations: PropTypes.shape(),
  showWhiteLabelLogoUrl: PropTypes.bool,
  logoUrl: PropTypes.string,
};

Footer.defaultProps = {
  presentations: {
    countryFooterPaymentIcons: undefined,
    countryFooterCol1: undefined,
    countryFooterCol2: undefined,
    countryFooterCol3: undefined,
    countryFooterCol4: undefined,
    countryFooterCol5: undefined,
  },
  logoUrl: '',
  showWhiteLabelLogoUrl: false,
};

const mapState = state => {
  const showWhiteLabelLogoUrl = state.config?.salesChannel?.logoUrl;
  const logoUrl = state.config?.salesChannel?.logoUrl;

  const mappedState = {
    countryCode: state.intl.countryCode,
    salesChannelGroup: state.config?.salesChannel?.group,
    presentations: state.config.presentations[state.intl.countryCode],
  };

  if (showWhiteLabelLogoUrl && logoUrl) {
    mappedState.showWhiteLabelLogoUrl = showWhiteLabelLogoUrl;
    mappedState.logoUrl = logoUrl;
  }
  return mappedState;
};

export default compose(withStyles(s), injectIntl, connect(mapState))(Footer);
